const SCHOLARSHIP_SCHEMA = `
    {
        status
        createdAt
        updatedAt
    }
`;

const CURRENT_STATUS_SCHEMA = `
    {
        status
        admin_author
        author
        createdAt
        updatedAt
    }
`;

const LAYOFF_SCHEMA = `
    {
        has_laid_off
        laid_off_company
        laid_off_description
    }
`;

export { LAYOFF_SCHEMA, SCHOLARSHIP_SCHEMA, CURRENT_STATUS_SCHEMA };
