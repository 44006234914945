import ApolloClientManager from "@/Logic/GraphQL/ApolloClientManager";
import { UPDATE_USER_COURSE_STATUS } from "@/Logic/GraphQL/Mutations/UserCourseStatus.mutation";
import {
  UserCoursePermissionsEnum,
  UserScholarshipStepsEnum,
} from "@/outscal-commons-frontend/Enums";
import UserAccessTypeEnum from "@/outscal-commons-frontend/Enums/userAccessTypeEnum";
import UTMManager from "../Services/UTMManager";
import CourseLeadSourceEnum from "@/outscal-commons-frontend/Enums/CourseLeadSourceEnum";
import UserCourseService from "./UserCourse.service";

class BaseUserCourse {
  constructor(course, user, courseFromStrapi) {
    this.isProUser =
      user?.userDetails?.user_access?.type ==
      UserAccessTypeEnum.getValue("pro-trial-user");
    this.statusUpdateMapping = {};
    this.course = course;
    this.courseFromStrapi = courseFromStrapi;
  }

  getUpgradButtonText(permissions) {
    let showTest = "";
    if (permissions?.length) {
      let currentPermissions = this.getCurrentPermissions(permissions);
      let coursePermissions = Object.keys(
        this.courseFromStrapi?.amountData || {}
      );
      for (let perm of coursePermissions) {
        if (!currentPermissions.includes(perm)) {
          showTest = " Upgrade Plan";
          break;
        }
      }
    }
    return showTest;
  }

  updateUserCourseStatus = async ({ courseSlug, waitlistSource }) => {
    const utms = UTMManager.instance.lastUtm;
    const response = await ApolloClientManager.client.mutate({
      mutation: UPDATE_USER_COURSE_STATUS,
      variables: {
        courseSlug,
        utms,
        waitlistSource,
      },
    });

    return response.data?.updateUserCourseAccess;
  };

  getUserCourseStatus = (user, course_slug) => {
    const currentCourseStatus = user?.userCourseStatus?.find(
      (courseStatus) => courseStatus?.course?.slug == course_slug
    );
    return {
      currentStatus: currentCourseStatus?.current_status?.status,
      coursePermissions: currentCourseStatus?.permissions,
    };
  };

  getUserScholarshipStatus = (user, course_slug) => {
    const status = user?.userScholarships?.find((scholarship) =>
      scholarship.steps.some(
        (step) =>
          step.type === UserScholarshipStepsEnum.getValue("waitlist") &&
          step?.condition?.waitlistCourse === course_slug
      )
    );
    this.userScholarshipStatus = status;
  };

  getCurrentPermissions = (permissions = []) => {
    return (
      permissions?.map((item) => UserCoursePermissionsEnum.getEnum(item)) || []
    );
  };

  getCoursePermissionPrice({
    paymentType = "CONTENT",
    amountData = {},
    permissions = [],
    isLoggedIn = false,
  }) {
    let currentPermissions = this.getCurrentPermissions(permissions);

    if (!paymentType || !amountData || !amountData[paymentType]) {
      return 0;
    }
    let basePrice = amountData[paymentType].amount;

    if (!isLoggedIn || currentPermissions.includes(paymentType)) {
      return basePrice;
    }
    const deductions = amountData[paymentType].deductions || {};

    currentPermissions.forEach((permission) => {
      if (deductions[permission]) {
        basePrice -= deductions[permission];
      }
    });
    return Math.max(basePrice, 0);
  }

  getActionPostStatusUpdate = (userCourseStatus) => {
    return this.statusUpdateMapping[userCourseStatus]
      ? this.statusUpdateMapping[userCourseStatus]
      : {};
  };

  getSubscriptionAction = (link, isLoggedin, userAllCourseStatus) => {
    const currentStatus = userAllCourseStatus?.find(
      (courseStatus) =>
        courseStatus?.course?.slug == UserCourseService.instance?.course?.slug
    );
    return {
      name: "subscription-flow",
      params: { link, isLoggedin, currentStatus },
    };
  };

  getEMIAction = (auth) => {
    let action;
    if (auth?.token) {
      action = { name: "check-make-payment", params: { withEMI: true } };
    } else {
      action = {
        name: "logout",
        params: {
          waitlistSource: CourseLeadSourceEnum.getValue("pricing-section"),
        },
      };
    }

    return action;
  };
}

export default BaseUserCourse;
