import BaseEnum from "./BaseEnum";

const UserScholarshipStepsEnum = new BaseEnum(
  [
    "profile",
    "waitlist",
    "refer",
    "email-verification",
    "resume",
    "discord",
    "booking",
  ],
  [
    "Profile",
    "Waitlist",
    "Refer",
    "EmailVerification",
    "Resume",
    "Discord",
    "Booking",
  ]
);

export default UserScholarshipStepsEnum;
