import { LAYOFF_SCHEMA, SCHOLARSHIP_SCHEMA, CURRENT_STATUS_SCHEMA } from "../Schemas/UserCourseStatus.schema";

const { gql } = require("@apollo/client");

const GET_USER_COURSE_STATUS = gql`
  query GetUserCourseStatus($course_slug: String!) {
    getUserCourseStatus(course_slug: $course_slug) {
        _id
        user
        course
        current_status ${CURRENT_STATUS_SCHEMA}
        program_goal
        program_expectation
        game_dev_knowledge
        enrollment_source
        utms
        pending_reviews
        permissions
        modules
        givenHrsPerDay
        requiredCourseCompletionTime { months }
        snooze_end_date
        scholarship ${SCHOLARSHIP_SCHEMA}
        scholarship_history ${SCHOLARSHIP_SCHEMA}
        layoff_data ${LAYOFF_SCHEMA}
    }
  }
`;

export { GET_USER_COURSE_STATUS };
