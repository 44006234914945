import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentStatus,
  setCourseSlug,
  setCourseType,
  setPreviewLink,
  setCurrentPermissions,
  setWaitListNumber,
  setCourseDiscountTime,
  setBrochureLink,
  clearCourseStatus,
  setScholarshipStatus,
  setScholarshipType,
} from "../reducers/userCourseStatusReducer";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import ApolloClientManager from "@/Logic/GraphQL/ApolloClientManager";
import { GET_USER_COURSE_STATUS } from "@/Logic/GraphQL/Queries/UserCourseStatus.query";

const useUserCourseStatusAction = () => {
  const dispatch = useDispatch();
  const userCourseStatus = useSelector((state) => state.userCourseStatus);

  const setCurrentCourseStatus = (currentStatus) => {
    dispatch(setCurrentStatus(currentStatus));
  };

  const setCurrentScholarshipStatus = (currentScholarshipStatus) => {
    dispatch(setScholarshipStatus(currentScholarshipStatus))
  }

  const setCurrentScholarshipType = (scholarshipType) => {
    dispatch(setScholarshipType(scholarshipType))
  }

  const setCurrentCoursePermissions = (coursePermissions) => {
    dispatch(setCurrentPermissions(coursePermissions));
  };

  const setCurrentCourseSlug = (courseSlug) => {
    dispatch(setCourseSlug(courseSlug));
  };

  const setCurrentCourseType = (type) => {
    dispatch(setCourseType(type));
  };

  const setCurrentCoursePrevLink = (link) => {
    dispatch(setPreviewLink(link));
  };

  const setUserWaitListNumber = (waitListNumber) => {
    dispatch(setWaitListNumber(waitListNumber));
  };

  const setCurrentCourseDiscountTime = (discountTime) => {
    dispatch(setCourseDiscountTime(discountTime));
  };

  const setCurrentCourseBrochureLink = (link) => {
    dispatch(setBrochureLink(link));
  };

  const clearCourseStatusDetails = () => {
    dispatch(clearCourseStatus());
  };
  const getUserCourseStatusDetails = async () => {
    let res = await ApolloClientManager.client.query({
      query: GET_USER_COURSE_STATUS,
      variables: {
        course_slug: UserCourseService.instance.course.slug,
      },
    });
    if (res.data.getUserCourseStatus) {
      setCurrentCourseStatus(res.data.getUserCourseStatus.current_status.status);
      setCurrentCoursePermissions(res.data.getUserCourseStatus.permissions);
    }
  };

  return {
    userCourseStatus,
    setCurrentCourseStatus,
    setCurrentCoursePermissions,
    setCurrentCourseSlug,
    setCurrentCourseType,
    setCurrentCoursePrevLink,
    setUserWaitListNumber,
    setCurrentCourseDiscountTime,
    setCurrentCourseBrochureLink,
    setCurrentScholarshipStatus,
    setCurrentScholarshipType,
    clearCourseStatusDetails,
    getUserCourseStatusDetails
  };
};

export default useUserCourseStatusAction;
