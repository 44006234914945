import { gql } from "@apollo/client";
import { CURRENT_STATUS_SCHEMA } from "../Schemas/UserCourseStatus.schema";

const UPDATE_USER_COURSE_STATUS = gql`
  mutation UpdateUserCourseAccess($courseSlug: String!, $utms: JSON, $waitlistSource: Int) {
    updateUserCourseAccess(courseSlug: $courseSlug, utms: $utms, waitlistSource: $waitlistSource) {
        _id
        current_status ${CURRENT_STATUS_SCHEMA}
        permissions
  }
  }
`;

export { UPDATE_USER_COURSE_STATUS };
